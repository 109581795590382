import s from './ProgressBar.module.css'

export const ProgressBar = ({progress}) => {
    const normalizedProgress = progress > 100 ? 100 : progress;

    const progressStyle = {
        width: `${normalizedProgress}%` // Устанавливаем ширину в процентах
    };

    return <>
        <p className={s.text}>{normalizedProgress}%</p>
         <div className={s.ProgressBar}>
            <div className={s.progress} style={progressStyle}></div>
        </div>
    </>
}