import React, { useState } from "react";
import s from "./LoginForm.module.css";
import { useForm } from "react-hook-form";
import { Button } from "../../ui/Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Input from "../../ui/Input/Input";

// Проверки
const schema = Yup.object({
  email: Yup.string()
    .email("Введите корректный email")
    .required("Email обязателен"),
  password: Yup.string()
    .min(8, "Пароль должен содержать не менее 8 символов")
    .required("Пароль обязателен"),
});

export const LoginForm = () => {
  //Ошибка авторизации
  const [authError, setAuthError] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit", resolver: yupResolver(schema) });

  const handleInputChange = () => {
    if (authError) {
      setAuthError(null);
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    // console.log("Отправляемые данные:", payload);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const jsonData = await response.json();
      if (response.ok) {
        // console.log("Ответ сервера:", jsonData);
        if (jsonData.token) {
          localStorage.setItem("token", jsonData.token);
        } else {
          setAuthError("Логин или пароль введены неверно");
          return;
        }

        setAuthError(null);
        navigate("/my_courses", { replace: true });
      } else {
        setAuthError(
          jsonData.message || `HTTP error! status: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Ошибка при авторизации", error);
      setAuthError("Логин или пароль введены неверно");
    }
  };

  /*   console.log(authError); */

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form} noValidate>
      <div className={s.wrapper}>
        <Input
          type="email"
          placeholder="Email"
          name="email"
          register={register}
          errors={errors}
          errorMessage={authError}
          backgroundColor="white"
          onInput={handleInputChange}
        />

        <Input
          type="password"
          placeholder="Пароль"
          name="password"
          register={register}
          errors={errors}
          errorMessage={authError}
          showToggle
          backgroundColor="white"
          onInput={handleInputChange}
        />
      </div>

      <Button
        className={s.button}
        submit
        text="Войти"
        type={"blue"}
        width="w-100"
      />
      {authError && <p className={s.error}>{authError}</p>}
    </form>
  );
};
