import React, { forwardRef, useState } from "react";
import InputMask from "react-input-mask";
import clsx from "clsx";
import s from "./Input.module.css";
import eyeOpen from "../../assets/icons/eye-open.svg";
import eyeClose from "../../assets/icons/eye-close.svg";

// Два типа бэкграунда в проекте, white и lightblue (default)
const Input = forwardRef(
  (
    {
      type,
      placeholder,
      name,
      register, // React-hook-form, обработка формы   ///СТАРЬЕ
      validationOptions = {},
      // register, // Обновлено для получения ref и правил валидации
      errors = {}, // React-hook-form, ошибки ввода формы
      errorMessage, // Сообщение ошибки от сервера
      icon,
      showToggle, // Флаг для показа иконки переключения видимости пароля
      backgroundColor = "lightblue", //white, lightblue
      label,
      className,
      value,
      onChange,
      onInput,
      page,
      mask,
    },
    ref
  ) => {
    // Состояние видимости пароля
    const [isShown, setIsShown] = useState(false);

    // Переключение видимости пароля
    const toggleVisibility = (e) => {
      e.preventDefault(); // Мера предосторожности, что бы иконки не отрабатывали как сабмит
      setIsShown(!isShown);
    };

    return (
      <div className={clsx(s.input__wrapper, className)}>
        {label && (
          <label className={s.label} htmlFor={name}>
            {label}
          </label>
        )}
        {mask ? (
          <InputMask
            inputRef={ref}
            value={value}
            onChange={onChange}
            onInput={onInput}
            name={name}
            type={type}
            placeholder={placeholder}
            mask={mask}
            maskChar={null} // Убираем маску, когда ввод не завершён
            className={clsx(
              s.input,
              backgroundColor === "white" && s.input__white,
              (errors[name] || errorMessage) && s.input__error
            )}
          />
        ) : (
          <input
            ref={ref}
            value={value}
            onChange={onChange}
            onInput={onInput}
            name={name}
            type={showToggle ? (isShown ? "text" : type) : type}
            placeholder={placeholder}
            {...(register && { ...register(name, validationOptions) })}
            className={clsx(
              s.input,
              backgroundColor === "white" && s.input__white,
              (errors[name] || errorMessage) && s.input__error
            )}
          />
        )}

        {/* Глазик */}
        {showToggle && (
          <button
            type="button"
            className={clsx(
              s.eyeIcon,
              page === "profile" && s.eyeIcon__profile
            )}
            onClick={toggleVisibility}
          >
            <img src={isShown ? eyeOpen : eyeClose} alt="Toggle visibility" />
          </button>
        )}
        {/* Для инпутов с иконкой, не связанных с паролем */}
        {icon && !showToggle && (
          <button type="button" className={s.icon}>
            <img src={icon} alt={placeholder} />
          </button>
        )}
        {errors[name] && <p className={s.error}>{errors[name]?.message}</p>}
      </div>
    );
  }
);

export default Input;
