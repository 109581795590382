import { useState } from "react";
import { LoginForm } from "../LoginForm/LoginForm";
import s from "./LoginBlock.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";

export const LoginBlock = ({ className }) => {
  return (
    <div className={clsx(s.authorize__block, className)}>
      <h1 className={s.title}>Вход</h1>
      <LoginForm />
      <Link className={s.link} to="/reset-password">
        <button className={s.forgot}>Забыли пароль?</button>
      </Link>
    </div>
  );
};
