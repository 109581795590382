import React from "react";
import s from "./Spinner.module.css";
import clsx from "clsx";

export const Spinner = ({ className }) => {
  return (
    <div className={clsx(s.ldsRoller, className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
