import s from "./AllCourses.module.css";
import { useState, useEffect } from "react";
import { Card } from "../../components/Card/Card";
import { Container } from "../../layouts/Container/Container";
import { Title } from "../../ui/Title/Title";
import { Sheet } from "../../ui/Sheet/Sheet";
import { Layout } from "../../layouts/Layout/Layout";
import Input from "../../ui/Input/Input";
import { Chip } from "../../ui/Chip/Chip";
import { spheres } from "../MyCourses/data";
import reset from "../../assets/icons/reset.svg";
import { Tab } from "../../ui/Tab";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../ui/Spinner/Spinner";

export const AllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [myCourse, addMyCourse] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const navigate = useNavigate();

  const [openFilters, setOpenFilters] = useState(false);
  const [activeTags, setActiveTags] = useState([]); // для хранения активных тегов
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // ЛОГИКА ДЛЯ ПЕРЕКЛЮЧЕНИЯ АКТИВНЫХ ТЕГОВ
  const toggleTag = (tag) => {
    setActiveTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  //СБРОС ТЕГОВ ПО КНОПКЕ СБРОСИТЬ ВЫБОР
  const removeTags = () => {
    setActiveTags([]);
  };

  // ЧТО БЫ ОТРАБАТЫВАЛИ ВСЕ ФИЛЬТРЫ И ПОИСК
  const applyFilters = () => {
    let result = courses;
    if (searchQuery) {
      result = result.filter((course) =>
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (activeTags.length > 0) {
      result = result.filter((course) =>
        activeTags.some((tag) => course.tag.includes(tag))
      );
    }
    setFilteredCourses(result);
  };

  useEffect(() => {
    applyFilters();
  }, [courses, activeTags, searchQuery]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Timezone': 'Europe/Moscow',
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures/all`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        let data2 = data.filter(el =>{
          const now = new Date();
          const end = new Date(el.end_date + 'Z');
          if (end >= now){
            return el
          }
        })
        setCourses(data2);
        setFilteredCourses(data2);
        
      } catch (error) {
        console.error(error);
      } finally{
        setIsLoading(false)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Timezone': 'Europe/Moscow',
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const now = new Date();
        //фильтруем на просроченность курса
        const filteredData = data.filter(
          (item) => new Date(item.end_date) > now
        );

        //фильтруем на повторяемость курса
        const uniqueCourses = Object.values(
          filteredData.reduce((acc, item) => {
            acc[item.lecture_id] = item;
            return acc;
          }, {})
        );

        // Запись отфильтрованных данных в состояние
        addMyCourse(uniqueCourses);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const inputHandler = (event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
  };

  return (
    <Layout>
      <Container>
        {isLoading ? <Spinner className={s.spinner}/> :
        <section className={s.allCourses}>
          {courses.length > 0 && <Sheet />}
          <Title>Каталог</Title>
          {courses.length > 0 && (
            <>
              <div className={s.search}>
                <Input
                  className={s.input}
                  placeholder={"Поиск"}
                  onChange={inputHandler}
                />

                <Tab
                  text="Выбрать компетенции"
                  onClick={() => setOpenFilters((prev) => !prev)}
                  type={!openFilters ? "disabled" : ""}
                  isActive={openFilters}
                  icon
                  q={activeTags.length > 0 && <span>{activeTags.length}</span>}
                />

                {activeTags.length > 0 && (
                  <button className={s.reset} onClick={removeTags}>
                    <img src={reset} alt="" /> Сбросить выбор
                  </button>
                )}
              </div>

              {openFilters && (
                <div className={s.filters}>
                  {spheres.map((sphere, index) => (
                    <Chip
                      key={index}
                      text={sphere}
                      onClick={() => toggleTag(sphere)}
                      isActive={activeTags.includes(sphere)}
                      type="disabled"
                    />
                  ))}
                </div>
              )}
            </>
          )}

          <div className="cards">
            {filteredCourses.map((course, index) => (
              <Link
                //если существует курс в назначенных - переводим на соотвтетствующую страницу
                to={
                  myCourse.some((el) => el.lecture.id == course.id)
                    ? `/my_courses/page_course/${course.id}`
                    : `/all_courses/page_course/${course.id}`
                }
                key={index}
              >
                <Card
                  key={index}
                  img={course.image}
                  alt="Картинка"
                  title={course.title}
                  chipText={course.tag || undefined}
                  chip="green"
                  descr={course.description}
                  buttonType="round"
                  buttonText=""
                  id={course.id}
                  page="AllCourses"
                />
              </Link>
            ))}
          </div>
        </section>
      }
      </Container>
    </Layout>
  );
};
