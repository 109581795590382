// import s from "./Select.module.css";
// import { useState } from "react";
// import clsx from "clsx";

// // СЕЙЧАС ЭТОТ СЕЛЕКТ ПО СУТИ ТОЛЬКО ДЛЯ СТРАНИЦЫ МОЙ ПРОФИЛЬ, ДЛЯ ПЕРЕИСПОЛЬЗУЕМОСТИ НУЖНО ПЕРЕДЕЛАТЬ
// export const Select = ({
//   label,
//   name,
//   value,
//   onChange,
//   data,
//   placeholder,
//   className,
// }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const handleItemClick = (item) => {
//     onChange(item);
//     setIsOpen(false);
//   };

//   return (
//     <div className={clsx(s.select, className)}>
//       {label && (
//         <label className={s.label} htmlFor={name}>
//           {label}
//         </label>
//       )}
//       <div className={s.dropdown}>
//         <div className={s.dropdown__wrapper}>
//           <button
//             className={s.dropdown__button}
//             onClick={() => setIsOpen(!isOpen)}
//             type="button"
//           >
//             {value || placeholder}
//           </button>
//         </div>

//         <ul
//           className={clsx(s.dropdown__list, isOpen && s.dropdown__list_visible)}
//         >
//           {/* ПЕРЕБОР ДЛЯ ПРОФИЛЯ, объект с бэка, поэтому кривой такой */}
//           {data?.map((item) => (
//             <li
//               key={item}
//               className={s.dropdown__item}
//               onClick={() => handleItemClick(item)}
//             >
//               {item}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

import React, { useState, forwardRef, useEffect } from "react";
import clsx from "clsx";
import s from "./Select.module.css";

// СЕЙЧАС ЭТОТ СЕЛЕКТ ПО СУТИ ТОЛЬКО ДЛЯ СТРАНИЦЫ МОЙ ПРОФИЛЬ, ДЛЯ ПЕРЕИСПОЛЬЗУЕМОСТИ НУЖНО ПЕРЕДЕЛАТЬ
export const Select = forwardRef(
  (
    {
      label,
      name,
      value,
      onChange,
      data,
      placeholder,
      className,
      isLoading,
      errors,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
      setCurrentValue(value || "");
    }, [value]);

    const handleItemClick = (item) => {
      onChange(item);
      setIsOpen(false);
      setCurrentValue(item);
    };

    return (
      <div className={clsx(s.select, className)}>
        {label && (
          <>
            <label className={s.label} htmlFor={name}>
              {label}
            </label>
          </>
        )}
        <div className={clsx(s.dropdown, errors[name] && s.select__error)}>
          <div className={clsx(s.dropdown__wrapper)}>
            <button
              className={clsx(
                s.dropdown__button,
                isOpen && s.dropdown__button_active,
                !currentValue && placeholder && s.select__placeholder
              )}
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              ref={ref}
            >
              {currentValue ? currentValue : placeholder}
            </button>
          </div>

          <ul
            className={clsx(
              s.dropdown__list,
              isOpen && s.dropdown__list_visible
            )}
          >
            {data?.map((item) => (
              <li
                key={item}
                className={s.dropdown__item}
                onClick={() => handleItemClick(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        {errors[name] && <p className={s.error}>{errors[name]?.message}</p>}
      </div>
    );
  }
);
