export const getBarStyle = (level, isLast) => {
  let height, backgroundColor, opacity;
  switch (level) {
    case "Дефицитарный уровень":
      height = "50px";
      backgroundColor = isLast ? "#FF7D1E" : "rgba(9, 48, 106, 0.4)";
      opacity = "1";
      break;
    case "Требуемый уровень":
      height = "100px";
      backgroundColor = isLast ? "#8ac540" : "rgba(9, 48, 106, 0.4)";
      opacity = "1";
      break;
    case "Уровень превосходства":
      height = "150px";
      backgroundColor = isLast ? "#00a442" : "rgba(9, 48, 106, 0.4)";
      opacity = "1";
      break;
    default:
      height = "50px";
      backgroundColor = "rgba(9, 48, 106, 0.4)";
  }
  return { height, backgroundColor };
};
