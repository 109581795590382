import s from "./PageCloseCourse.module.css";
import placeholderImage from "./img/img.jpg";
import { useState, useEffect } from "react";
import { Container } from "../../layouts/Container/Container";
import { CardTask } from "../../ui/CardTask/CardTask";
import { Button } from "../../ui/Button/Button";
import { ProgressBar } from "../../ui/ProgressBar/ProgressBar";
import { Layout } from "../../layouts/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { Tab } from "../../ui/Tab";

export const PageCloseCourse = () => {
  const { id } = useParams();
  const lectureID = id;
  const [courses, setCourses] = useState([]);
  const [lesson, getLesson] = useState([]);
  const [viewContent, setViewContent] = useState(true);
  const [activeTab, setActiveTab] = useState("blue");

  const navigate = useNavigate();

  const handleClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setViewContent(tab === "blue");
    }
  };

  //определить кол-во дней до конечной даты
  const getDaysUntilEndDate = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    
    // Вычисляем разницу в миллисекундах
    const differenceInTime = end.getTime() - now.getTime();
    
    // Конвертируем разницу в днях
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    
    return differenceInDays;
  };

  //отправка запроса на назначение оценочной сессии
  async function createCourse() {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      'Timezone': 'Europe/Moscow',
    });
  
    try {

      // Выполняем POST запрос
      const postResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/assign-lecture`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            lecture_id: foundLecture.id,
            // days: 14,
            days: getDaysUntilEndDate(foundLecture.end_date),
          }),
          redirect: "follow",
        }
      );
  
      if (!postResponse.ok) {
        throw new Error("POST request failed");
      }
  
      window.location.href = `/my_courses/page_course/${foundLecture.id}`;
      
    } catch (error) {
      console.error("Error during session creation:", error);
    }
  }

  //получить данные по лекции (верхняя информация)
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Timezone': 'Europe/Moscow',
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures/all`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCourses(data);
        // console.log(data);
        
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  //получить данные задания (нижняя информация)
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Timezone': 'Europe/Moscow',
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures/${lectureID}/all-lessons`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        getLesson(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [lectureID]);

  //найти нужную лекцию из массива объектов
  const foundLecture = courses.find((item) => item.id === +lectureID);

  //поменять формат даты на "число месяц"
  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("ru-RU", options);
  };

  return (
    <Layout>
      <Container>
        <section className={s.pageCourse}>
          <Button text="← Назад" type="back" onClick={() => navigate(-1)} />
          <div className={s.top__content}>
            {courses.length > 0 && (
              <div className={s.status}>
                <p className={s.status__title}>Начать курс</p>
                
                {foundLecture.requires_entry_assessment_session === 0 && foundLecture.requires_exit_assessment_session === 0 && (
                  <p className={s.status__descr}>
                    Для получения доступа к курсу не требуется сдавать оценочное тестирование
                  </p>
                )}
                {foundLecture.requires_entry_assessment_session === 1 && foundLecture.requires_exit_assessment_session === 0 && (
                  <p className={s.status__descr}>
                    Для получения доступа к курсу потребуется сдать входное тестирование
                  </p>
                )}
                {foundLecture.requires_entry_assessment_session === 0 && foundLecture.requires_exit_assessment_session === 1 && (
                  <p className={s.status__descr}>
                    После прохождения курса потребуется сдать выходное тестирование
                  </p>
                )}
                {foundLecture.requires_entry_assessment_session === 1 && foundLecture.requires_exit_assessment_session === 1 && (
                  <p className={s.status__descr}>
                    Для получения доступа к курсу требуется сдать входное и выходное тестирование по завершении курса
                  </p>
                )}
                
                <Button 
                  text="Начать курс" 
                  type="green" 
                  width="w-100" 
                  onClick={() => createCourse()}/>
              </div>
            )}

            {courses.length > 0 && (
              <div className={s.info}>
                <h1 className={s.info__title}>{foundLecture.title}</h1>
                <ProgressBar progress={0} />
                <img
                  className={s.img}
                  src={
                    foundLecture.image
                      ? `https://educorp.fa.ru/storage/${foundLecture.image}`
                      : placeholderImage
                  }
                  alt="фото курса"
                />
                <div className={s.info__numbers}>
                  <div className={s.info__card}>
                    <p className={s.card__title}>{formatDate(foundLecture.start_date)}</p>
                    <p className={s.card__descr}>начало курса</p>
                  </div>
                  <div className={s.info__card}>
                    <p className={s.card__title}>{formatDate(foundLecture.end_date)}</p>
                    <p className={s.card__descr}>завершить до</p>
                  </div>
                  <div className={s.info__card}>
                    <p className={s.card__title}>
                      {0} из {Object.values(lesson).length}
                    </p>
                    <p className={s.card__descr}>всего занятий</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={s.buttons}>
            <div className={s.buttons__left}>
              <Tab
                text="Содержание"
                type={activeTab === "blue" ? "blue" : ""}
                onClick={() => handleClick("blue")}
              />
              {courses.length > 0 && foundLecture.description && (
                <Tab
                  text="Описание"
                  type={activeTab !== "blue" ? "blue" : ""}
                  onClick={() => handleClick("")}
                />
              )}
            </div>
          </div>
          {viewContent ? (
            <div className={s.content}>
              {lesson.map((course, index) => (
                <CardTask
                  key={index}
                  task={`Занятие №${index + 1}`}
                  text={course.title}
                  type={"disabled"}
                />
              ))}
            </div>
          ) : (
            <p className={s.descr}>{foundLecture.description}</p>
          )}
        </section>
      </Container>
    </Layout>
  );
};