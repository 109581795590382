import React, { useState, useEffect, useRef } from "react";
import s from "./TaskPage.module.css";
import { Layout } from "../../layouts/Layout/Layout";
import { Container } from "../../layouts/Container/Container";
import { Button } from "../../ui/Button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";

export const TaskPage = () => {
  const [ltiData, setLtiData] = useState(null);
  const [lesson, getLesson] = useState([]);
  const { id } = useParams();
  const { assign } = useParams();

  const iframeRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lessons/${assign}/${id}/lti`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLtiData(data);
      } catch (error) {
        console.error(error);
        
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (assign !== null) {
      const requestOptions = {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }),
        redirect: "follow",
      };

      const fetchData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/assign/${assign}/lessons`,
            requestOptions
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          getLesson(data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [assign]);

  useEffect(() => {
    if (ltiData) {
      const form = document.createElement("form");
      form.setAttribute("id", "data-form");
      form.setAttribute("action", "https://coreapp.ai/integration");
      form.setAttribute("method", "POST");
      form.setAttribute("target", "data-frame");

      Object.entries(ltiData).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);

      form.submit();

      return () => {
        document.body.removeChild(form);
      };
    }
  }, [ltiData]);
  
  const checkIframeContent = () => {
    try {
      const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      
      // Получаем содержимое тега body
      const bodyContent = iframeDocument.body.textContent || iframeDocument.body.innerText;

      // Проверяем наличие искомого текста
      if (bodyContent.includes("Resource associated with the passed id not found")) {
        console.error("Упс");
      }
    } catch (error) {
      console.error('Ошибка при доступе к содержимому iframe:', error);
    }
  };

  return (
    <Layout>
      <Container>
        <div className={s.taskPage_btns}>
          <Button text="← Назад" type="back" onClick={() => navigate(`/my_courses/page_course/${lesson[0].lecture_id}`)} />
          
          {lesson.length > 0 && lesson[lesson.length - 1].id > id ? (  
            <Link to={`/task_page/${assign}/${+id + 1}`}>
              <Button text="Далее"/>
            </Link>
          ) : null}
        </div>
      </Container>
      {ltiData && (
          <iframe
            ref={iframeRef}
            name="data-frame"
            style={{ width: "100%", minHeight:'125vh', border: "none" }}
            onLoad={checkIframeContent}
          ></iframe>
      )}
    </Layout>
  );
};
