import s from "./Tab.module.css";
import clsx from "clsx";
import arrow from "../../assets/icons/arrow.svg";

//виды кнопок type:(blue)

export const Tab = ({ type, text, q, icon, className, onClick, isActive, isActiveSelect }) => {
  return (
    <button
      className={clsx(s.Tab, s[type], className, {
        [s.active]: isActive,
        [s.select]: isActiveSelect,
      })}
      onClick={onClick}
    >
      {text}
      <sup className={s.quantity}>{q !== 0 && q}</sup>
      {icon && (
        <img
          className={s.arrow}
          src={arrow}
          alt="иконка"
          style={{ transform: isActiveSelect ? "rotate(180deg)" : "rotate(0)" }}
        />
      )}
    </button>
  );
};
