import s from "./Card.module.css";
import placeholderImage from './img/img.jpg'
import calendar from "./img/calendar.svg";
import { Chip } from "../../ui/Chip/Chip";
import { Button } from "../../ui/Button/Button";

export const Card = ({
  start,
  img,
  alt,
  title,
  descr,
  time,
  chip,
  chipText,
  buttonType,
  buttonText,
  id,
  onClick,
  page,
  status
}) => {

  return (
    <article className={s.Card}>
      {start ? <Chip type="absolute" text={start} onClick={onClick} /> : ""}
      <img
        className={s.Card__img}
        src={img ? `https://educorp.fa.ru/storage/${img}` : placeholderImage}
        alt={alt}
      />
      {chipText ? (
        <div className={s.Card__chips}>
          <Chip type={chip} text={chipText} />{" "}
        </div>
      ) : (
        ""
      )}
      <div className={s.Card__text}>
        <p className={s.Card__title}>{title}</p>
        <p className={s.Card__descr}>{descr}</p>
      </div>
      <div className={s.Card__bottom}>
        <div className={s.Card__calendar}>
          {start ? (
            <>
              <img src={calendar} alt="календарь" />
              <p className={s.Card__time}>Завершить до {time}</p>
            </>
          ) : (
            ""
          )}
        </div>
        <Button type={buttonType} text={buttonText} />
      </div>
    </article>
  );
};
