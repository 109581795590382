import { createBrowserRouter, redirect } from "react-router-dom";
import { AuthorizePage } from "../pages/AuthorizePage";
import { Profile } from "../pages/Profile/Profile";
import { MyCourses } from "../pages/MyCourses";
import { AllCourses } from "../pages/AllCourses";
import { PageCourse } from "../pages/PageCourse/PageCourse";
import { PageCloseCourse } from "../pages/PageCloseCourse/PageCloseCourse";
import { TaskPage } from "../pages/TaskPage/TaskPage";
import { GradePage } from "../pages/GradePage/GradePage";
import { CasePage } from "../pages/CasePage/CasePage";
import { GradeSession } from "../pages/GradeSession/GradeSession";
import { CompetenceProfile } from "../pages/CompetenceProfile/CompetenceProfile";
import { CompetencePage } from "../pages/CompetencePage/CompetencePage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage/ResetPasswordPage";
import { RecoveryPasswordPage } from "../pages/RecoveryPasswordPage/RecoveryPasswordPage";

/* */
const requireAuth = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return redirect("/login");
  }
  return null;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthorizePage />,
    loader: async () => {
      const query = new URLSearchParams(window.location.search);
      const tokenFromUrl = query.get("token");
      const email = query.get("email");
      const tokenFromLS = localStorage.getItem("token");

      if (tokenFromUrl && email) {
        return redirect(`/recover?token=${tokenFromUrl}&email=${email}`);
      }

      if (tokenFromLS) {
        return redirect("/my_courses");
      }

      return null;
    },
  },
  {
    path: "/activate",
    element: <AuthorizePage />,
    loader: async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get("code");
      const email = query.get("email");
      if (!code || !email) {
        return redirect("/login");
      }
      return null;
    },
  },
  {
    path: "/recover",
    element: <RecoveryPasswordPage />,
    loader: async () => {
      const query = new URLSearchParams(window.location.search);
      const token = query.get("token");

      if (!token) {
        return redirect("/login");
      }
      return null;
    },
  },

  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/login",
    element: <AuthorizePage />,
  },
  {
    path: "/my_courses",
    element: <MyCourses />,
    loader: requireAuth,
  },
  {
    path: "/all_courses",
    element: <AllCourses />,
    loader: requireAuth,
  },
  {
    path: "/page_course/:id",
    element: <PageCourse />,
    loader: requireAuth,
  },
  {
    path: "/my_courses/page_course/:id",
    element: <PageCourse />,
    loader: requireAuth,
  },
  {
    path: "/all_courses/page_course/:id",
    element: <PageCloseCourse />,
    loader: requireAuth,
  },
  {
    path: "/task_page/:assign/:id",
    element: <TaskPage />,
    loader: requireAuth,
  },
  {
    path: "/grade_page/:id",
    element: <GradePage />,
    loader: requireAuth,
  },
  {
    path: "/case_page/:id",
    element: <CasePage />,
    loader: requireAuth,
  },
  {
    path: "/grade_session/:id",
    element: <GradeSession />,
    loader: requireAuth,
  },
  {
    path: "/result/:sphereId",
    element: <CompetencePage />,
    loader: requireAuth,
  },
  {
    path: "/profile",
    element: <Profile />,
    loader: requireAuth,
  },
  {
    path: "/competence",
    element: <CompetenceProfile />,
    loader: requireAuth,
  },
  {
    path: "*",
    element: <AuthorizePage />,
    loader: async () => {
      return setTimeout(() => {
        redirect("/login");
      }, 1000);
    },
  },
]);
