import { Link } from "react-router-dom";
import s from "./Logo.module.css";
import logo from "./img/logo.svg";

export const Logo = () => {
  return (
    <Link to="/">
      <div className={s.logo}>
        <img src={logo} className={s.logo__img} alt="логотип" />
        <p className={s.logo__text}>
          Институт повышения квалификации и профессиональной переподготовки
          работников
        </p>
      </div>
    </Link>
  );
};
