import React, { useState, useEffect } from "react";
import { Layout } from "../../layouts/Layout/Layout";
import { Container } from "../../layouts/Container/Container";
import s from "./CasePage.module.css";
import { Button } from "../../ui/Button/Button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export const CasePage = () => {
  const [ltiData, setLtiData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { sphereId, from } = location.state;

  // const id = 1


  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-testing/${id}/case_testing`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLtiData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (ltiData) {
      const form = document.createElement("form");
      form.setAttribute("id", "data-form");
      form.setAttribute("action", "https://coreapp.ai/integration");
      form.setAttribute("method", "POST");
      form.setAttribute("target", "data-frame");

      Object.entries(ltiData).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);

      form.submit();

      return () => {
        document.body.removeChild(form);
      };
    }
  }, [ltiData]);

  return (
    <Layout>
      <Container className={s.container}>
        <Button
          text="← Назад"
          type="back"
          onClick={() =>
            navigate(`/grade_session/${id}`, {
              state: { from: from },
            })
          }
        />

        <Button
          text="Результаты"
          type="green"
          onClick={() => navigate(`/result/${sphereId}`)}
        />
      </Container>
      {ltiData && (
        <iframe
          name="data-frame"
          style={{ width: "100%", height: "860px", border: "none" }}
        ></iframe>
      )}
    </Layout>
  );
};
