import { CompetenceCard } from "../../components/CompetenceCard/CompetenceCard";
import { Container } from "../../layouts/Container";
import { Layout } from "../../layouts/Layout/Layout";
import { Button } from "../../ui/Button/Button";
import { CardTask } from "../../ui/CardTask/CardTask";
import { Tab } from "../../ui/Tab";
import { Title } from "../../ui/Title/Title";
import s from "./CompetenceProfile.module.css";
import { spheres } from "./data";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import button from "../../assets/icons/button-arrow-down.svg";
import { Spinner } from "../../ui/Spinner/Spinner";
import { formatToRussianDate } from "../../helpers/formatToRussianDate";
import { getLevelCategory } from "../../helpers/getLevelCategory";

const filtersTagsNames = {
  Универсальный: "Универсальные",
  "Психолого-педагогический": "Психолого-педагогические",
  Предметный: "Предметные",
  Цифровой: "Цифровые",
};

export const CompetenceProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const sessionParam = params.get("session");
  const defaultActivePage = sessionParam ? 1 : 0;

  const [activePage, setActivePage] = useState(defaultActivePage);
  const [activeTag, setActiveTag] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [spheresList, setSpheresList] = useState([]);
  const [filteredSpheresList, setFilteredSpheresList] = useState(spheresList);

  const [actualSessions, setActualSessions] = useState([]);
  const [finishedSessions, setFinishedSessions] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // Состояние для отслеживания загрузки

  const applyFilters = (selectedTag) => {
    const result = spheresList.filter((sphere) => {
      if (selectedTag === "Все") return true; // Не фильтруем, если выбран тег "Все"

      const tagToMatch = Object.keys(filtersTagsNames).find(
        (key) => filtersTagsNames[key] === selectedTag
      );
      return tagToMatch ? sphere.tag === tagToMatch : true;
    });

    setFilteredSpheresList(result);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Устанавливаем загрузку в true перед началом запроса

        const [
          spheresResponse,
          actualSessionsResponse,
          finishedSessionsResponse,
          profileResponse,
        ] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/spheres`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/assessment-tests/actual`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/assessment-tests/results`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/profile`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        if (
          !spheresResponse.ok ||
          !actualSessionsResponse.ok ||
          !finishedSessionsResponse.ok ||
          !profileResponse.ok
        ) {
          throw new Error("Ошибка при загрузке данных");
        }

        const [
          spheresData,
          actualSessionsData,
          finishedSessionsData,
          profileData,
        ] = await Promise.all([
          spheresResponse.json(),
          actualSessionsResponse.json(),
          finishedSessionsResponse.json(),
          profileResponse.json(),
        ]);

        setSpheresList(spheresData);
        setFilteredSpheresList(spheresData);
        setActualSessions(actualSessionsData);
        setFinishedSessions(finishedSessionsData);
        setFirstName(profileData.student.firstName);
        setLastName(profileData.student.lastName);

        setIsLoading(false); // Устанавливаем загрузку в false после успешного получения данных
      } catch (error) {
        console.error("Ошибка:", error);
        setIsLoading(false); // Устанавливаем загрузку в false в случае ошибки
      }
    };

    fetchData();
  }, []);

  const handlePageClick = (num) => {
    setActivePage(num);
  };

  const handleSphereClick = (num, name) => {
    applyFilters(name);
    setActiveTag(num);
  };

  return (
    <Layout>
      <Container className={s.wrapper}>
        {isLoading ? (
          <Spinner className={s.spinner} />
        ) : (
          <>
            <Button text="← Назад" type="back" onClick={() => navigate(-1)} />

            <Title>Профиль компетенций</Title>

            <div className={s.card__info}>
              <p>{firstName}</p>
              <p>{lastName}</p>
            </div>

            <div className={s.tabs}>
              <Tab
                className={s.tabs__button}
                text="Общий"
                onClick={() => handlePageClick(0)}
                isActive={activePage === 0}
              />
              <Tab
                className={s.tabs__button}
                text="Оценочные сессии"
                onClick={() => handlePageClick(1)}
                isActive={activePage === 1}
              />
            </div>

            {activePage === 0 && (
              <>
                <div className={s.tabs}>
                  {spheres.map((el, i) => {
                    return (
                      <Tab
                        className={s.tabs__button}
                        text={el}
                        type="lightblue"
                        onClick={() => handleSphereClick(i, el)}
                        isActive={activeTag === i}
                        key={i}
                        q={
                          i === 0
                            ? spheresList.length
                            : spheresList.filter(
                                (sphere) =>
                                  sphere?.tag?.slice(0, -2) === el?.slice(0, -2)
                              ).length
                        }
                      />
                    );
                  })}
                </div>

                <div className={s.spheres__wrapper}>
                  {filteredSpheresList?.map((sphere, i) => {
                    return (
                      <CompetenceCard
                        key={i}
                        sphereId={sphere.id}
                        sphereName={sphere.name}
                      />
                    );
                  })}
                </div>
              </>
            )}

            {activePage === 1 && (
              <>
                <div className={s.session__type}>
                  <button className={s.session__button}>
                    <img src={button} alt="" />
                  </button>
                  <h2 className={s.session__title}>Назначенные</h2>
                </div>

                <div className={s.session__wrapper}>
                  {actualSessions && actualSessions.length > 0 ? (
                    actualSessions.map((session, i) => {
                      return (
                        <Link
                          to={`/grade_session/${session.id}`}
                          state={{ from: location.pathname }}
                          key={i}
                        >
                          <CardTask
                            session={session}
                            sessionId={session.id}
                            text={session.sphere.name}
                            task={"Оценочная сессия"}
                            test
                            endDate={formatToRussianDate(session.end_date)}
                          />
                        </Link>
                      );
                    })
                  ) : (
                    <div className={s.session__empty}>
                      Нет назначенных сессий
                    </div>
                  )}
                </div>

                <div className={s.session__type}>
                  <button className={s.session__button}>
                    <img src={button} alt="" />
                  </button>
                  <h2 className={s.session__title}>Завершенные</h2>
                </div>

                <div className={s.session__wrapper}>
                  {finishedSessions.map((session, i) => {
                    return (
                      <CardTask
                        session={session}
                        sessionId={session.id}
                        key={i}
                        complete
                        level={getLevelCategory(session.level)}
                        date={formatToRussianDate(
                          session.updated_at.split("T")[0]
                        )}
                        text={session.sphere.name}
                        task={"Оценочная сессия"}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </Container>
    </Layout>
  );
};
