import s from "./AuthorizePage.module.css";
import { Layout } from "../../layouts/Layout";
import { LoginBlock } from "../../components/LoginBlock";
import { ActivateBlock } from "../../components/ActivateBlock";
import { Container } from "../../layouts/Container";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const AuthorizePage = () => {
  const navigate = useNavigate();

  // Проверка ТОКЕН
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/my_courses", { replace: true }); // Используем replace, чтобы предотвратить возможность возвращения пользователя на эту страницу кнопкой назад в браузере
    }
  }, [navigate]);

  // Проверка Query-параметров
  const params = new URLSearchParams(window.location.search);
  let email = params.get("email");

  if (email) {
    email = decodeURIComponent(email).replace(/ /g, "+");
  }
  const code = params.get("code");

  return (
    <Layout className={s.authorize}>
      <Container className={s.container}>
        {email && code ? (
          <ActivateBlock
            email={email}
            code={code}
            className={s.authorize__block}
          />
        ) : (
          <LoginBlock className={s.authorize__block} />
        )}
      </Container>
    </Layout>
  );
};
