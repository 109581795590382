//страница из Моих курсов

import s from "./PageCourse.module.css";
import placeholderImage from "./img/img.jpg";
import { useState, useEffect } from "react";
import { Container } from "../../layouts/Container/Container";
import { CardTask } from "../../ui/CardTask/CardTask";
import { Button } from "../../ui/Button/Button";
import { ProgressBar } from "../../ui/ProgressBar/ProgressBar";
import { Layout } from "../../layouts/Layout/Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../ui/Spinner/Spinner";
import { Tab } from "../../ui/Tab";

export const PageCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const lectureID = id;
  const [courses, setCourses] = useState([]);
  const [lesson, getLesson] = useState([]);
  const [grade, checkGrade] = useState([]);
  const [viewContent, setViewContent] = useState(true);
  const [activeTab, setActiveTab] = useState("blue");
  const [flag, changeFlag] = useState(false);
  const [assign, setAssign] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const handleClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setViewContent(tab === "blue");
    }
  };

  //получить данные по лекции (верхняя информация)
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Timezone: "Europe/Moscow",
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        //убрать повторяющиеся курсы
        const uniqueCourses = Object.values(
          data.reduce((acc, item) => {
            acc[item.lecture_id] = item;
            return acc;
          }, {})
        );
        // Запись отфильтрованных данных в состояние
        setCourses(uniqueCourses);

        setAssign(uniqueCourses.find((el) => el.lecture.id === +id).id);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  //получить данные задания (нижняя информация)
  useEffect(() => {
    if (assign !== null) {
      const requestOptions = {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Timezone: "Europe/Moscow",
        }),
        redirect: "follow",
      };

      const fetchData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/assign/${assign}/lessons`,
            requestOptions
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          getLesson(data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [assign]);

  //получить данные оценочной сессии (верхняя информация)
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Timezone: "Europe/Moscow",
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assign-spheres`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        checkGrade(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  //отправка запроса на назначение оценочной сессии
  async function createSession(type) {
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Timezone: "Europe/Moscow",
    });

    try {
      setIsLoading(true);

      // Выполняем POST запрос
      const postResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/assign-sphere`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            sphere_id: foundLecture.lecture.spheres[0].id,
            days: 14,
            test_type: type,
          }),
          redirect: "follow",
        }
      );

      if (!postResponse.ok) {
        throw new Error("POST request failed");
      }

      // Выполняем GET запрос
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/assign-spheres`,
        {
          method: "GET",
          headers: headers,
          redirect: "follow",
        }
      );

      if (!getResponse.ok) {
        throw new Error("GET request failed");
      }

      const data = await getResponse.json();
      window.location.href = `/grade_session/${data[data.length - 1].id}`;
    } catch (error) {
      console.error("Error during session creation:", error);
    }
  }

  //поменять формат даты на "число месяц"
  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("ru-RU", options);
  };

  //расчет прогресса в процентах
  function getProgrssNumb(completedTasks, totalTasks) {
    // Если общее количество заданий равно нулю, процент выполнения также будет равен нулю
    if (totalTasks === 0) {
      return 0;
    }

    return Math.floor((completedTasks / totalTasks) * 100);
  }

  //найти нужную лекцию из массива объектов
  const foundLecture = courses.find((item) => item.lecture_id === +lectureID);

  //найти нужный тест по id-сферы среди всех тестов
  let foundGrade;
  {
    courses.length > 0 &&
      grade.length > 0 &&
      (foundGrade = grade.findLast(
        (item) => item.sphere_id === foundLecture.lecture.spheres[0]?.id
      ));
  }

  //определить статус курса (верхний левый блок)
  useEffect(() => {
    const timeCourseCreate = new Date(foundLecture?.created_at); //время назначения курса)
    const timeAllTaskComplete = new Date(foundLecture?.completed_at + "Z"); // время выполнения всех заданий курса, Z - для приведения времени к единому часовому поясу
    const timeLastGradeCreate = new Date(foundGrade?.created_at); // время назначения ОС
    //если есть только входное тестирование...
    if (
      foundLecture?.lecture.requires_entry_assessment_session === 1 &&
      foundLecture?.lecture.requires_exit_assessment_session === 0
    ) {
      // console.log("есть только входное тестирование");
      //и если тестирование не назначено...
      if (
        grade.length === 0 ||
        foundGrade === undefined ||
        timeCourseCreate > timeLastGradeCreate
      ) {
        //плашка - назначьте ОС, курс заблокирован
        // console.log("ОС не назначена");
        changeFlag("enterGradeNo");
        //или если тестирование не пройдено...
      } else if (foundGrade?.level === null) {
        //плашка - пройдите ОС, курс заблокирован
        // console.log("ОС не пройдена");
        changeFlag("enterGradeNotComplete");
        //но если входное тестирование пройдено, но не пройдены задания...
      } else if (
        foundGrade?.level !== null &&
        foundLecture?.completed_at === null
      ) {
        //плашки нет
        // console.log("ОС пройдена");
        changeFlag(false);
        //если проийдено и входное тестирование и задания...
      } else if (
        foundGrade?.level !== null &&
        foundLecture?.completed_at !== null
      ) {
        //плашка - Курс пройден
        // console.log("Курс пройден");
        changeFlag("Complete");
      }
    } //если есть только вЫходное тестирование...
    else if (
      foundLecture?.lecture.requires_entry_assessment_session === 0 &&
      foundLecture?.lecture.requires_exit_assessment_session === 1
    ) {
      // console.log("есть только вЫходное тестирование");
      //если задания не пройдены...
      if (foundLecture?.completed_at === null) {
        //плашки нет
        // console.log("задания не пройдены");
        changeFlag(false);
        //если задания пройдены, но ОС не назначена...
      } else if (
        (foundLecture?.completed_at !== null && foundGrade === undefined) ||
        (foundGrade !== null && timeAllTaskComplete > timeLastGradeCreate)
      ) {
        //плашка - назначьте ОС, курс НЕ заблокирован
        // console.log("задания пройдены, ОС НЕ назначена");
        changeFlag("exitGradeNo");
        //если задания пройдены, ОС назначена, но не пройдена...
      } else if (
        foundLecture?.completed_at !== null &&
        foundGrade?.level === null
      ) {
        //плашка - пройдите ОС, курс НЕ заблокирован
        // console.log("задания пройдены, ОС назначена");
        changeFlag("exitGradeNotComplete");
        //если задания и ОС пройдены...
      } else if (
        foundLecture?.completed_at !== null &&
        foundGrade?.level !== null &&
        timeAllTaskComplete < timeLastGradeCreate
      ) {
        //плашка - Курс пройден
        // console.log("задания пройдены, ОС пройдена");
        changeFlag("Complete");
      }
    } //если есть и входное, и выходное тестирование...
    else if (
      foundLecture?.lecture.requires_entry_assessment_session === 1 &&
      foundLecture?.lecture.requires_exit_assessment_session === 1
    ) {
      // console.log("есть и входное, и выходное тестирование");
      //и если входное тестирование не назначено...
      if (
        grade.length === 0 ||
        foundGrade === undefined ||
        (foundGrade.test_type === "out" &&
          timeCourseCreate > timeLastGradeCreate)
      ) {
        //плашка - назначьте ОС, курс заблокирован
        // console.log("ОС1 не назначена");
        changeFlag("enterGradeNo");
        //или если входное тестирование не пройдено...
      } else if (
        foundGrade?.level === null &&
        foundLecture?.completed_at === null
      ) {
        //плашка - пройдите ОС, курс заблокирован
        // console.log("ОС1 не пройдена");
        changeFlag("enterGradeNotComplete");
        //но если входное тестирование пройдено, но не пройдены задания...
      } else if (
        foundGrade?.level !== null &&
        foundLecture?.completed_at === null
      ) {
        //плашка - ОС пройдена
        // console.log("задания не пройдены");
        changeFlag("enterGradeCompleteHasExit");
        // или если задания пройдены, но ОС2 не назначена...
      } else if (
        foundGrade?.level !== null &&
        foundLecture?.completed_at !== null &&
        timeAllTaskComplete > timeLastGradeCreate
      ) {
        //плашка - назначьте ОС2, курс НЕ заблокирован
        // console.log("задания пройдены, ОС2 НЕ назначена");
        changeFlag("exitGrade2No");
        //или если задания пройдены, но ОС2 не пройдена...
      } else if (
        foundGrade?.level === null &&
        foundLecture?.completed_at !== null
      ) {
        //плашка - пройдите ОС2, курс НЕ заблокирован
        // console.log("задания пройдены, ОС2 НЕ пройдена");
        changeFlag("exitGradeNotComplete");
        //если задания пройдены и ОС2 пройдена...
      } else {
        //плашка - Курс выполнен
        // console.log("задания и ОС2 пройдены");
        changeFlag("Complete");
      }
    } //если нет ни входного, ни выходного тестирования...
    else if (
      foundLecture?.lecture.requires_entry_assessment_session === 0 &&
      foundLecture?.lecture.requires_exit_assessment_session === 0
    ) {
      //и если все задания выполнены...
      if (foundLecture?.completed_at !== null) {
        //Плашка - Курс выполнен
        changeFlag("Complete");
      }
    }
  });

  return (
    <Layout>
      <Container>
        {isLoading ? (
          <Spinner className={s.spinner} />
        ) : (
          <section className={s.pageCourse}>
            <Button
              text="← Назад"
              type="back"
              onClick={() => navigate("/my_courses")}
            />

            <div className={s.top__content}>
              {courses.length > 0 &&
              (flag === "enterGradeCompleteHasExit" || flag === "Complete") ? (
                //блок Оценочная сессия пройдена
                <div className={s.status}>
                  {flag === "enterGradeCompleteHasExit" ? (
                    <>
                      <p className={s.status__title}>
                        Оценочная сессия пройдена
                      </p>
                      <p className={s.status__descr}>
                        По завершении курса нужно будет пройти выходное
                        тестирование
                      </p>
                    </>
                  ) : (
                    <>
                      <p className={s.status__title}>
                        Поздравляем с прохождением курса!
                      </p>
                    </>
                  )}

                  <Link to={`/result/${foundGrade?.sphere_id}`}>
                    <Button text="Результаты" type="blue" width="w-100" />
                  </Link>
                </div>
              ) : flag === "enterGradeNo" ||
                flag === "enterGradeNotComplete" ||
                flag === "exitGradeNo" ||
                flag === "exitGrade2No" ||
                flag === "exitGradeNotComplete" ? (
                //блок Пройдите оценочную сессию
                <div className={s.status}>
                  <p className={s.status__title}>Пройдите оценочную сессию</p>
                  {(flag === "enterGradeNo" ||
                    flag === "enterGradeNotComplete") && (
                    <p className={s.status__descr}>
                      Пройдите входную оценочную сессию чтобы получить доступ к
                      курсу
                    </p>
                  )}
                  {(flag === "exitGradeNo" ||
                    flag === "exitGradeNotComplete" ||
                    flag === "exitGrade2No") && (
                    <p className={s.status__descr}>
                      Пройдите выходную оценочную сессию чтобы завершить курс
                    </p>
                  )}
                  {grade.length > 0 &&
                  foundGrade !== undefined &&
                  new Date(foundLecture.created_at) <
                    new Date(foundGrade?.created_at) &&
                  (flag === "enterGradeNo" ||
                    flag === "enterGradeNotComplete" ||
                    flag === "exitGradeNo" ||
                    flag === "exitGradeNotComplete") ? (
                    //или ссылка после обновления страницы
                    <Link
                      to={`/grade_session/${foundGrade?.id}`}
                      state={{ from: location.pathname }}
                    >
                      <Button text="Пройти оценку" type="green" width="w-100" />
                    </Link>
                  ) : (
                    <Button
                      text="Начать сессию"
                      type="green"
                      width="w-100"
                      onClick={() => {
                        if (flag === "enterGradeNo") {
                          createSession("in");
                        } else if (
                          flag === "exitGradeNo" ||
                          flag === "exitGrade2No"
                        ) {
                          createSession("out");
                        }
                      }}
                    />
                  )}
                </div>
              ) : (
                <div></div>
              )}

              {/* главная информация */}
              {courses.length > 0 && (
                <div className={s.info}>
                  <h1 className={s.info__title}>
                    {foundLecture.lecture.title}
                  </h1>
                  <ProgressBar
                    progress={getProgrssNumb(
                      foundLecture.completed_lessons,
                      foundLecture.lecture.lessons_count
                    )}
                  />
                  <img
                    className={s.img}
                    src={
                      foundLecture.lecture.image
                        ? `https://educorp.fa.ru/storage/${foundLecture.lecture.image}`
                        : placeholderImage
                    }
                    alt="фото курса"
                  />
                  <div className={s.info__numbers}>
                    <div className={s.info__card}>
                      <p className={s.card__title}>
                        {formatDate(foundLecture.start_date)}
                      </p>
                      <p className={s.card__descr}>начало курса</p>
                    </div>
                    <div className={s.info__card}>
                      <p className={s.card__title}>
                        {formatDate(foundLecture.end_date)}
                      </p>
                      <p className={s.card__descr}>завершить до</p>
                    </div>
                    <div className={s.info__card}>
                      <p className={s.card__title}>
                        {foundLecture.completed_lessons} из{" "}
                        {foundLecture.lecture.lessons_count}
                      </p>
                      <p className={s.card__descr}>всего занятий</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={s.buttons}>
              <div className={s.buttons__left}>
                <Tab
                  text="Содержание"
                  type={activeTab === "blue" ? "blue" : ""}
                  onClick={() => handleClick("blue")}
                />
                {courses.length > 0 && foundLecture.lecture.description && (
                  <Tab
                    text="Описание"
                    type={activeTab !== "blue" ? "blue" : ""}
                    onClick={() => handleClick("")}
                  />
                )}
              </div>
            </div>
            {viewContent ? (
              <div className={s.content}>
                {lesson.map((course, index) =>
                  // Проверяем, является ли тип disabled
                  flag === "enterGradeNo" ||
                  flag === "enterGradeNotComplete" ? (
                    // Если да, то просто отображаем CardTask без Link
                    <CardTask
                      key={index}
                      task={`Занятие №${index + 1}`}
                      text={course.title}
                      complete={course.results?.complete ? "complete" : ""}
                      type={"disabled"}
                    />
                  ) : (
                    // Если нет, то отображаем CardTask внутри Link
                    <Link key={index} to={`/task_page/${assign}/${course.id}`}>
                      <CardTask
                        task={`Занятие №${index + 1}`}
                        text={course.title}
                        complete={
                          !course.results
                            ? ""
                            : course.results.complete
                            ? "complete"
                            : ""
                        }
                        type={course.disabled ? "disabled" : ""}
                      />
                    </Link>
                  )
                )}
              </div>
            ) : (
              <p className={s.descr}>{foundLecture.lecture.description}</p>
            )}
          </section>
        )}
      </Container>
    </Layout>
  );
};
