import { useForm } from "react-hook-form";
import Input from "../../ui/Input/Input";
import s from "./ResetPasswordForm.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button } from "../../ui/Button/Button";
import { useEffect, useState } from "react";
import { click } from "@testing-library/user-event/dist/click";
import { eventWrapper } from "@testing-library/user-event/dist/utils";
import clsx from "clsx";
import { Modal } from "../Modal/Modal";

// Проверки
const schema = Yup.object({
  email: Yup.string()
    .email("Введите корректный email")
    .required("Email обязателен"),
});

export const ResetPasswordForm = ({}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [sendMessage, setSendMessage] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit", resolver: yupResolver(schema) });

  useEffect(() => {
    const modalHandler = window.addEventListener("click", (e) => {
      e.stopPropagation();
      setIsOpenModal(false);
    });

    return () => window.removeEventListener("click", modalHandler);
  }, []);

  const onSubmit = async (data) => {
    /*     console.log(data); */
    const payload = {
      email: data.email,
    };

    try {
      /*       console.log(`TRY ${data}`); */
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/recover-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      setIsOpenModal(true);
      setEmail(data.email);
    } catch (error) {
      console.error("Ошибка: ", error);

      setIsOpenModal(true);
      setEmail(data.email);
    }
  };

  /*   console.log(sendMessage); */
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form} noValidate>
      {isOpenModal && (
        <Modal
          onSubmit={onSubmit}
          email={email}
          sendMessage={sendMessage}
          setIsOpenModal={setIsOpenModal}
          setSendMessage={setSendMessage}
        />
      )}

      <div className="wrapper">
        <Input
          type="email"
          placeholder="Email"
          name="email"
          register={register}
          errors={errors}
          backgroundColor="white"
        />
      </div>

      <Button
        className={s.button}
        submit
        text="Сбросить пароль"
        type={"blue"}
        width="w-100"
      />
    </form>
  );
};
