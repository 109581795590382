import s from "./Button.module.css";
import clsx from "clsx";
//submit - булевый пропс, обозначающий что кнопка сабмит
//виды кнопок type:(green, blue, lightblue, back, round, plus, down, up)
// isActive - активная кнопка в проекте, blue.  unactive - lightblue
export const Button = ({
  type,
  text,
  href,
  width,
  submit,
  className,
  isActive,
  onClick,
}) => {
  if (href) {
    return (
      <a className={clsx(s.Button, s[type], s[width])} href={href}>
        {text}
      </a>
    );
  } else {
    return (
      <button
        type={submit ? "submit" : "button"}
        onClick={onClick}
        className={clsx(s.Button, s[type], className, s[width], {
          [s.active]: isActive,
          [s.unactive]: !isActive,
        })}
      >
        {text}
      </button>
    );
  }
};
