// !ПЕРЕДЕЛАТЬ

import React, { createContext, useContext, useState } from "react";

const ProfileFormContext = createContext();

export const useProfileForm = () => useContext(ProfileFormContext);

export const ProfileFormProvider = ({ children }) => {
  const [profileFormData, setProfileFormData] = useState({});
  return (
    <ProfileFormContext.Provider
      value={{ profileFormData, setProfileFormData }}
    >
      {children}
    </ProfileFormContext.Provider>
  );
};
