import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import s from "./Dropdown.module.css";
import user from "./img/user.svg";
import logout from "./img/logout.svg";

export const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleLogout() {
    localStorage.clear();
  }

  return (
    <div className={s.Dropdown} ref={dropdownRef}>
      <button
        className={`${s.DropdownButton} ${isOpen ? s.DropdownOpen : ""}`}
        onClick={toggleDropdown}
      >
        Мой профиль
      </button>
      <ul className={`${s.listbox} ${isOpen ? s.visible : ""}`}>
        <li className={s.list}>
          <Link to={`/profile`} className={s.listAnker}>
            <img src={user} alt="иконка пользователя" />
            Мой профиль
          </Link>
        </li>
        <li className={s.list}>
          <Link to={`/login`} onClick={handleLogout} className={s.listAnker}>
            <img src={logout} alt="иконка выхода" />
            Выйти
          </Link>
        </li>
      </ul>
    </div>
  );
};
