import { Button } from "../Button/Button";
import s from "./Sheet.module.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../ui/Spinner/Spinner";

export const Sheet = () => {
  const [courses, setCourses] = useState([]);
  const [tests, setTests] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isGrade, getInfoAboutGrade] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const now = new Date();
        //фильтруем на просроченность курса
        const filteredData = data.filter(item => new Date(item.end_date) > now);
        const uniqueCourses = Object.values(
          filteredData.reduce((acc, item) => {
            acc[item.lecture_id] = item;
            return acc;
          }, {})
        );
        uniqueCourses.filter(el => el.completed_at === null);
        //филтруем на завершение заданий
        const finishCourses = uniqueCourses.filter(el => el.completed_at===null)

        //  фильтруем на наличие сферы компетенции
        const hasSphere = finishCourses.filter(el=>el.lecture.spheres.length>0)
  
        // Запись отфильтрованных данных в состояние
        setCourses(hasSphere);
      } catch (error) {
        console.error(error);
      } finally{
        setIsLoading(false)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assign-spheres`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        getInfoAboutGrade(data.some((el) => el.level == null));
        setTests(data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  let titleName;
  let allTasks;
  let completeTasks;
  let lectureID;

  if (courses.length > 0) {
    let course = courses[courses.length - 1];
    titleName = course.lecture.title;
    allTasks = course.lecture.lessons_count;
    completeTasks = course.completed_lessons;
    lectureID = course.lecture.id;
  }

  //расчет прогресса в процентах
  function getProgrssNumb(completedTasks, totalTasks) {
    if (totalTasks === 0) {
      return 0; // Если общее количество заданий равно нулю, процент выполнения также будет равен нулю
    }
    return Math.floor((completedTasks / totalTasks) * 100);
  }

  if (isLoading) {
    return <Spinner className={s.spinner}/>
  } else {

  return (
    (courses.length > 0 || tests.length > 0) && (
        (courses.filter((el) => el.completed_at == null).length > 0 ||
            isGrade === true) && (
            <div className={s.SheetContainer}>
                {/* блок с прогрессом по курсу, отображается если есть непройденный курс */}
                {courses.filter((el) => el.completed_at == null).length > 0 && (
                    <div className={s.SheetCover}>
                        <div className={s.Sheet}>
                            <div className={s.dropdownContent}>
                                <div className={s.Sheet__lecture} key={courses[0].lecture.id}>
                                    <div className={s.Sheet__left}>
                                        <p
                                            className={`${s.Sheet__title} ${
                                                isOpen ? "" : s.shortName
                                            }`}
                                        >
                                            {courses[0].lecture.title}
                                        </p>
                                        <div className={s.Sheet__line}>
                                            <div
                                                className={s.Sheet__progress}
                                                style={{
                                                    width: `${getProgrssNumb(
                                                        courses[0].completed_lessons,
                                                        courses[0].lecture.lessons_count
                                                    )}%`,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <Link to={`/my_courses/page_course/${courses[0].lecture.id}`}>
                                        <Button type="blue" text="Продолжить" />
                                    </Link>
                                    <div className={s.dropdownButton} onClick={toggleDropdown}>
                                        {isOpen ? <Button type="up" /> : <Button type="down" />}
                                    </div>
                                </div>
                            </div>
                            {isOpen && (
                                <div className={s.dropdownContent}>
                                    {courses.slice(1).map((el) => (
                                        <div className={s.Sheet__lecture} key={el.lecture.id}>
                                            <div className={s.Sheet__left}>
                                                <p className={s.Sheet__title}>{el.lecture.title}</p>
                                                <div className={s.Sheet__line}>
                                                    <div
                                                        className={s.Sheet__progress}
                                                        style={{
                                                            width: `${getProgrssNumb(
                                                                el.completed_lessons,
                                                                el.lecture.lessons_count
                                                            )}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                            {/* <Link to={`/my_сourses/page_course/${el.lecture.id}`}>
                                                <Button type='blue' text="Продолжить" />
                                            </Link> */}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* блок с переходом на оценочную сессию, отображается если назначена оценочная сессия */}
                {isGrade && (
                    <div className={s.SheetCover}>
                        <div className={s.Sheet}>
                            <div className={s.dropdownContent}>
                                <div
                                    className={`${s.Sheet__lecture} ${s.Sheet__lecture2}`}
                                >
                                    <div className={s.Sheet__left}>
                                        <p className={`${s.Sheet__title} ${s.Sheet__title2}`}>
                                            Вам назначены оценочные сессии
                                        </p>
                                    </div>
                                    <Link to={`/competence?session=1`}>
                                        <Button type="green" text="Пройти" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    )
  )}
};
