import s from './Footer.module.css'
import {Container} from '../Container/Container'
import {Logo} from '../../ui/Logo/Logo'
import telegram from './img/telegram.svg';
import vk from './img/vk.svg';
import youtube from './img/youtube.svg'
import clsx from 'clsx';

export function Footer(){
    const currentYear = new Date().getFullYear();
    
    return <footer className={s.Footer}>
        <Container>
            <div className={s.Footer__top}>
                <Logo></Logo>
                <div className={s.social}>
                    <p className={s.social__title}>Присоединяйтесь к нам в социальных сетях</p>
                    <div className={s.social__items}>
                        <a href="##">
                            <div className={clsx(s.social__icon, s.telegram)}>
                                <img src={telegram} alt="telegram" />
                            </div>
                        </a>
                        <a href="##">
                            <div className={clsx(s.social__icon, s.vk)}>
                                <img src={vk} alt="vk" />
                            </div>
                        </a>
                        <a href="##">
                            <div className={clsx(s.social__icon, s.youtube)}>
                                <img src={youtube} alt="youtube" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className={s.Footer__bottom}>
                <p className={s.company}>ФИНАНСОВЫЙ УНИВЕРСИТЕТ © ® {currentYear}</p>
                <a className={s.politic} href="##">Политика конфиденциальности</a>
            </div>
        </Container>
    </footer>
}