import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import s from "./Layout.module.css";
import { Navigation } from "../Navigation/Navigation";
import clsx from "clsx";

export const Layout = ({ children, className }) => {
  return (
    <>
      <Header />
      <Navigation />
      <main className={clsx(s.main, className)}>{children}</main>
      <Footer />
    </>
  );
};
