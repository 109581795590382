import clsx from "clsx";
import { Button } from "../../ui/Button/Button";
import s from "./Modal.module.css";

export const Modal = ({email, sendMessage, setIsOpenModal, setSendMessage, onSubmit }) => {
  return (
    <div className={s.modal} onClick={(e) => e.stopPropagation()}>
      <div className={s.modal__content}>
        <h1 className={s.title}>Восстановление доступа</h1>
        <p className={s.descr}>
          На вашу почту: <span className={s.email}>{email}</span> отправлена
          ссылка для входа в систему
        </p>
        <Button
          className={clsx(s.button, s.modal__btn)}
          submit
          text="Закрыть"
          type={"blue"}
          width="w-100"
          onClick={() => {
            setIsOpenModal(false);
            setSendMessage(false);
          }}
        />

        {!sendMessage ? (
          <button
            className={s.back}
            onClick={() => {
              onSubmit({ email });
              setSendMessage(true);
            }}
          >
            Повторная отправка
          </button>
        ) : (
          <p className={s.message}>Ссылка отправлена</p>
        )}
      </div>
    </div>
  );
};
