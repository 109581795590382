export const getLevelDescription = (level) => {
  switch (level) {
    case "Дефицитарный уровень":
      return "Степень проявления компетенции, отражающая дефициты \nв способностях для выполнения профессиональных задач \nв соответствии с критерием оценки";
    case "Требуемый уровень":
      return "Степень проявления компетенции, отражающая способности, достаточные для выполнения профессиональных задач в соответствии с критерием оценки, и учитывающая требования федеральныхи локальных нормативных правовых актов";
    case "Уровень превосходства":
      return "Степень проявления компетенции, превосходящая достаточные способности для выполнения профессиональных задач в соответствии с критерием/ями";
    default:
      return "Степень проявления компетенции, отражающая дефициты \nв способностях для выполнения профессиональных задач \nв соответствии с критерием оценки";
  }
};
