import s from './NoCourses.module.css'
import { Button } from '../../ui/Button/Button'
import cards from './img/cards.svg'
import { Link } from 'react-router-dom'

export const NoCourses = () => {
    return <div className={s.NoCourses}>
        <img className={s.cards} src={cards} alt="пустые карточки" />
        <p className={s.text}>Вы ещё не добавили ни одного курса,  перейдите в каталог, чтобы выбрать новый курс для прохождения</p>
        <Link to="/all_courses">
            <Button 
                className={s.button}
                type = 'green'
                text = 'Выбрать курс'
            />
        </Link>
    </div>
}