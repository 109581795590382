// !ПЕРЕДЕЛАТЬ

import React, { createContext, useContext, useState } from "react";

const PasswordFormContext = createContext();

export const usePasswordForm = () => useContext(PasswordFormContext);

export const PasswordFormProvider = ({ children }) => {
  const [passwordFormData, setPasswordFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  return (
    <PasswordFormContext.Provider
      value={{ passwordFormData, setPasswordFormData }}
    >
      {children}
    </PasswordFormContext.Provider>
  );
};
