import s from "./MyCourses.module.css";
import { useState, useEffect } from "react";
import { Card } from "../../components/Card/Card";
import { Container } from "../../layouts/Container/Container";
import { NoCourses } from "../../components/NoCourses/NoCourses";
import { Title } from "../../ui/Title/Title";
import { Tab } from "../../ui/Tab";
import { Sheet } from "../../ui/Sheet/Sheet";
import { Layout } from "../../layouts/Layout/Layout";
import Input from "../../ui/Input/Input";
import { Chip } from "../../ui/Chip/Chip";
import { spheres } from "./data";
import reset from "../../assets/icons/reset.svg";
import { Link } from "react-router-dom";
import { Spinner } from "../../ui/Spinner/Spinner";

export const MyCourses = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [FinishCourses, setFinishCourses] = useState(false);
  const [activeTab, setActiveTab] = useState("blue");
  const [openFilters, setOpenFilters] = useState(false);
  const [activeTags, setActiveTags] = useState([]); // для хранения активных тегов
  const [searchQuery, setSearchQuery] = useState("");
  const [tests, setTests] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (type) => {
    if (type !== activeTab) {
      setActiveTab(type);
      showContent();
    }
  };

  // ЛОГИКА ДЛЯ ПЕРЕКЛЮЧЕНИЯ АКТИВНЫХ ТЕГОВ
  const toggleTag = (tag) => {
    setActiveTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  //СБРОС ТЕГОВ ПО КНОПКЕ СБРОСИТЬ ВЫБОР
  const removeTags = () => {
    setActiveTags([]);
  };

  // ЧТО БЫ ОТРАБАТЫВАЛИ ВСЕ ФИЛЬТРЫ И ПОИСК
  const applyFilters = () => {
    let result = courses;
    if (searchQuery) {
      result = result.filter((course) =>
        course.lecture.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (activeTags.length > 0) {
      result = result.filter((course) =>
        activeTags.some((tag) => course.lecture.tag.includes(tag))
      );
    }

    setFilteredCourses(result);
  };

  useEffect(() => {
    applyFilters();
  }, [courses, activeTags, searchQuery]);

  const showContent = () => {
    setFinishCourses(!FinishCourses);
  };

  //поменять формат даты для ярлыка Старт
  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("ru-RU", options);
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Timezone': 'Europe/Moscow',
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log(data);
        //фильтруем на просроченность курса
        // const now = new Date();
        // const filteredData = data.filter(
        //   (item) => new Date(item.end_date) > now
        // );


        //фильтруем на повторяемость курса
        const uniqueCourses = Object.values(
          // filteredData.reduce((acc, item) => {
          data.reduce((acc, item) => {
            acc[item.lecture_id] = item;
            return acc;
          }, {})
        );

        // Запись отфильтрованных данных в состояние
        setCourses(uniqueCourses);
        setFilteredCourses(uniqueCourses);
      } catch (error) {
        console.error(error);
      } finally{
        setIsLoading(false)
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Timezone': 'Europe/Moscow',
      }),
      redirect: "follow",
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assign-spheres`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // const hasSphere = data.filter(el=>el.lecture.spheres.length>0)
        setTests(data); //получить все тесты
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // Функция для определения завершенности курса
  const isCourseCompleted = (course) => {
    if(tests.length>0){
      let foundGrade = tests.filter(el => el.sphere_id === course.lecture?.spheres[course.lecture.spheres.length-1]?.id)//последняя ОС курса
      const timeAllTaskComplete = new Date(course?.completed_at + 'Z'); // время выполнения всех заданий курса
      const timeLastGradeCreate = new Date(foundGrade[foundGrade.length-1]?.created_at); // время назначения ОС
      //если у курса только входное тестирование или у него вообще нет ОС...
      if((course?.lecture.requires_entry_assessment_session === 1 || course?.lecture.requires_entry_assessment_session === 0) && course?.lecture.requires_exit_assessment_session === 0){
        //и у курса выполнены все задания
        if(course?.completed_at !== null ){
          return course
        }
      } //если у курса только вЫходное тестирование...
      else if (course?.lecture.requires_entry_assessment_session === 0 && course?.lecture.requires_exit_assessment_session === 1){
        if(foundGrade[foundGrade.length-1]?.level !== null && (timeAllTaskComplete < timeLastGradeCreate)){
          return course
        }
      } //если у курса и выходное и вЫходное тестирование...
      else if (course?.lecture.requires_entry_assessment_session === 1 && course?.lecture.requires_exit_assessment_session === 1){
        if(foundGrade[foundGrade.length-1]?.level !== null && course.completed_at !== null && (timeAllTaskComplete < timeLastGradeCreate)){
          return course
        }
      }
    }
  };

  const inputHandler = (event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
  };

  //проверка на просроченность курса
  const isCourseActive = (course) => {
    const now = new Date();
    const endDate = new Date(course.end_date)
    if(endDate >= now){
      return course
    }
  };

  return (
    <Layout>
      <Container>
        {isLoading ? <Spinner className={s.spinner}/> :
          <section className={s.MyCourses}>
            {tests.length > 0 && (
              <div className={s.SheetContainer}>
                <Sheet />
              </div>
            )}
            <div className={s.flex}>
              <Title>Мои курсы</Title>

              <div className={s.wrapper__filter}>
                <div className={s.tabs}>
                  <Tab
                    text="В процессе"
                    type={activeTab === "blue" ? "blue" : ""}
                    q={
                        <span>
                          {
                            filteredCourses.filter((el) => !isCourseCompleted(el) &&
                            isCourseActive(el))
                              .length
                          }
                        </span>
                    }
                    onClick={() => handleClick("blue")}
                  />
                  <Tab
                    text="Завершены"
                    type={activeTab !== "blue" ? "blue" : ""}
                    q={
                      filteredCourses.filter((el) => isCourseCompleted(el))
                        .length > 0 && (
                        <span>
                          {
                            filteredCourses.filter((el) => isCourseCompleted(el))
                              .length
                          }
                        </span>
                      )
                    }
                    onClick={() => handleClick("")}
                  />
                </div>
                {courses.length > 0 && (
                  <>
                    <div className={s.search}>
                      <Input
                        className={s.input}
                        placeholder={"Поиск"}
                        onChange={inputHandler}
                      />
                      <Tab
                        text="Выбрать компетенции"
                        onClick={() => setOpenFilters((prev) => !prev)}
                        type={!openFilters ? "disabled" : ""}
                        isActiveSelect={openFilters}
                        icon
                        q={
                          activeTags.length > 0 && (
                            <span>{activeTags.length}</span>
                          )
                        }
                      />

                      {activeTags.length > 0 && (
                        <button className={s.reset} onClick={removeTags}>
                          <img src={reset} alt="" /> Сбросить выбор
                        </button>
                      )}
                    </div>

                    {openFilters && (
                      <div className={s.filters}>
                        {spheres.map((sphere, index) => (
                          <Chip
                            key={index}
                            text={sphere}
                            onClick={() => toggleTag(sphere)}
                            isActive={activeTags.includes(sphere)}
                            type="disabled"
                          />
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
              {courses.length > 0 && (
                <div className="cards">
                  {filteredCourses.map((course, index) =>
                    (FinishCourses && isCourseCompleted(course)) ||
                    (!FinishCourses &&
                      !isCourseCompleted(course) &&
                      isCourseActive(course)) ? (
                      <Link to={`/my_courses/page_course/${course.lecture.id}`} key={index}>
                        <Card
                          img={course.lecture.image}
                          alt="Картинка"
                          title={course.lecture.title}
                          descr={course.lecture.description}
                          buttonType="round"
                          buttonText=""
                          time={formatDate(course.end_date)}
                          start={"Старт " + formatDate(course.start_date)}
                          chipText={course.lecture.tag || undefined}
                          chip="green"
                          id={course.lecture.id}
                          page="MyCourses"
                          status={course.completed_at}
                          // id={course.id}
                        />
                      </Link>
                    ) : (
                      courses.length === 0 && <NoCourses />
                    )
                  )}
                </div>
              )}
              {courses.length === 0 && <NoCourses />}
            </div>
          </section>
        }
      </Container>
    </Layout>
  );
};