import highlevel from "../assets/icons/highlevel.svg";
import normallevel from "../assets/icons/normallevel.svg";
import lowlevel from "../assets/icons/lowlevel.svg";
import s from "../components/CompetenceCard/CompetenceCard.module.css";

export const getLevelCategory = (level) => {
  if (level < 69) {
    return {
      icon: lowlevel,
      text: "Дефицитарный уровень",
      type: "level_low",
      className: s.level__text_low,
    };
  } else if (level <= 90) {
    return {
      icon: normallevel,
      text: "Требуемый уровень",
      type: "level_normal",
      className: s.level__text_normal,
    };
  } else {
    return {
      icon: highlevel,
      type: "level_high",
      text: "Уровень превосходства",
      className: s.level__text_high,
    };
  }
};
