import s from "./Navigation.module.css";
// import {Bell} from '../../ui/Bell/Bell'
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { Container } from "../Container";
import { Link } from "react-router-dom";

export const Navigation = () => {
  // Проверяем, есть ли данные в localStorage
  const isLoggedIn = localStorage.getItem("token");

  // Если пользователь авторизован (localStorage содержит токен), отображаем навигацию
  if (isLoggedIn) {
    return (
      <Container>
        <div className={s.navigation}>
          <div className={s.navigation__site}>
            <ul className={s.navigation__ul}>
              <Link to="/my_courses">
                <li className={s.navigation__li}>
                  <span className={s.navigation__a}>Мои курсы</span>
                </li>
              </Link>
              <Link to="/all_courses">
                <li className={s.navigation__li}>
                  <span className={s.navigation__a}>Каталог</span>
                </li>
              </Link>
              <Link to={"/competence"}>
                {" "}
                <li className={s.navigation__li}>
                  <span className={s.navigation__a}>Профиль компетенций</span>
                </li>
              </Link>
            </ul>
          </div>
          <div className={s.navigation__dashboard}>
            {/* <Bell/> */}
            <Dropdown />
          </div>
        </div>
      </Container>
    );
  } else {
    // Если пользователь не авторизован, возвращаем null, что приведет к отсутствию отображения навигации
    return null;
  }
};
