import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "../../layouts/Container";
import { useEffect, useState } from "react";
import { Layout } from "../../layouts/Layout";
import { Button } from "../../ui/Button/Button";
import { Title } from "../../ui/Title/Title";
import * as Yup from "yup";
import s from "./Profile.module.css";
import Input from "../../ui/Input/Input";
import { Select } from "../../ui/Select/Select";
import { educationLevel, gender } from "./data";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  ProfileFormProvider,
  useProfileForm,
} from "../../contexts/ProfileFormContext";
import {
  PasswordFormProvider,
  usePasswordForm,
} from "../../contexts/PasswordFormContext";
import { Spinner } from "../../ui/Spinner/Spinner";

// YUP, ВАЛИДАЦИЯ ФОРМ --- ПРОВЕРКИ
const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Поле обязательно для заполнения")
    .min(2, "Имя должно содержать не менее 2 символов")
    .matches(
      /^[A-Za-zА-Яа-яЁё\-']+$/,
      "Имя может содержать только буквы, дефисы и апострофы"
    ),
  lastName: Yup.string()
    .required("Поле обязательно для заполнения")
    .matches(
      /^[A-Za-zА-Яа-яЁё\-']+$/,
      "Фамилия может содержать только буквы, дефисы и апострофы"
    ),
  citizenship: Yup.string().required("Поле обязательно для заполнения"),
  birth_date: Yup.date()
    .required("Дата рождения обязательна")
    .typeError("Введите корректную дату")
    .max(new Date(), "Дата рождения не может быть в будущем"),
  phone: Yup.string()
    .transform((value) => value?.replace(/\D/g, "")) // Удаляем все нецифровые символы
    .matches(
      /^\d{11}$/,
      "Введите корректный телефонный номер в формате +7 (XXX) XXX-XX-XX"
    )
    .required("Поле обязательно для заполнения"),
  // federal_subject: Yup.string().required("Поле обязательно для заполнения"),
  email: Yup.string()
    .email("Некорректный email")
    .required("Поле обязательно для заполнения"),
  snils: Yup.string()
    .transform((value) => value?.replace(/\D/g, "")) // Удаляем все нецифровые символы
    .matches(/^\d{11}$/, "СНИЛС должен быть в формате XXX-XXX-XXX XX")
    .required("СНИЛС обязателен"),
  gender: Yup.string().required("Поле обязательно для заполнения"),
});

const passwordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required("Введите текущий пароль"),
  new_password: Yup.string()
    .required("Введите новый пароль")
    .min(8, "Пароль должен быть не менее 8 символов"),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Пароли должны совпадать")
    .required("Подтвердите новый пароль"),
});

// Форматирование даты для сабмита
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const ProfileForm = () => {
  const navigate = useNavigate();
  const { profileFormData, setProfileFormData } = useProfileForm();
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [currentRegion, setCurrentRegion] = useState("");
  const [currentCountry, setCurrentCountry] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(profileValidationSchema),
    defaultValues: profileFormData || {},
  });

  const onSubmit = async (data) => {
    try {
      const currentCitizenship = countries.find(
        (country) => country[1] === data.citizenship
      );
      const currentRegion = regions.find(
        (region) => region[1] === data.federal_subject
      );

      const currentDepartment = departments.find(
        (department) => department[1] === data.department
      );

      switch (data.education_level) {
        case "Без образования":
          data.education_level = "none";
          break;
        case "Среднее":
          data.education_level = "secondary";
          break;
        case "Среднее специальное":
          data.education_level = "special_secondary";
          break;
        case "Высшее":
          data.education_level = "higher";
          break;
        default:
          break;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          ...data,
          federal_subject: currentRegion ? currentRegion[0] : null,
          department: currentDepartment ? currentDepartment[0] : null,
          citizenship: currentCitizenship ? currentCitizenship[0] : null,
          birth_date: data.birth_date
            ? formatDate(new Date(data.birth_date))
            : null,
          gender: data.gender === "Мужской" ? "m" : "f",
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Произошла ошибка ${response.status} ${response.statusText}`
        );
      } else {
        alert("Профиль успешно обновлен!");
        navigate("/");
      }
    } catch (error) {
      console.error("Произошла ошибка при обновлении профиля", error);
      alert("Ошибка при обновлении профиля!");
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/regions`)
      .then((response) => response.json())
      .then((data) =>
        setRegions(
          Object.entries(data).sort(
            (a, b) => parseInt(a[0], 10) - parseInt(b[0], 10)
          )
        )
      );
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/departments`)
      .then((response) => response.json())
      .then((data) =>
        setDepartments(
          Object.entries(data).sort(
            (a, b) => parseInt(a[0], 10) - parseInt(b[0], 10)
          )
        )
      );
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/countries`)
      .then((response) => response.json())
      .then((data) => setCountries(Object.entries(data)));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (
        regions.length > 0 &&
        countries.length > 0 &&
        departments.length > 0
      ) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/profile`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Ответ сервера: ${response.status}`);
          }

          const data = await response.json();
          const { student } = data;

          // if (student.birth_date) {
          //   student.birth_date = student.birth_date
          //     .split("-")
          //     .reverse()
          //     .join(".");
          // }

          if (student.gender) {
            student.gender = student.gender === "m" ? "Мужской" : "Женский";
          }

          if (student.education_level) {
            switch (student.education_level) {
              case "none":
                student.education_level = "Без образования";
                break;
              case "secondary":
                student.education_level = "Среднее";
                break;
              case "special_secondary":
                student.education_level = "Среднее специальное";
                break;
              case "higher":
                student.education_level = "Высшее";
                break;
            }
          }

          Object.keys(student).forEach((key) => {
            if (key !== "citizenship" && key !== "federal_subject") {
              setValue(key, student[key]);
            }
          });

          const foundRegion = regions?.find(
            (r) => r[0] === student.federal_subject
          );
          const foundCountry = countries?.find(
            (c) => c[0] === student.citizenship
          );
          const foundDepartment = departments?.find(
            (d) => d[0] === student.department
          );

          if (foundDepartment) {
            setValue("department", foundDepartment[1]);
            setCurrentDepartment(foundDepartment[1]);
          }

          if (foundRegion) {
            setValue("federal_subject", foundRegion[1]);
            setCurrentRegion(foundRegion[1]);
          }
          if (foundCountry) {
            setValue("citizenship", foundCountry[1]);
            setCurrentCountry(foundCountry[1]);
          }

          setProfileFormData(student);
        } catch (error) {
          console.error("Ошибка:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [regions, countries, departments]);

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <Spinner className={s.spinner} />
      ) : (
        <>
          <div className={s.form__inputs}>
            <Input
              className={s.input__item}
              label={"Имя"}
              type="text"
              name="firstName"
              placeholder="Введите имя"
              errors={errors}
              register={register}
            />
            <Input
              className={s.input__item}
              label={"Должность"}
              type="text"
              name="position"
              errors={errors}
              placeholder="Введите должность"
              register={register}
            />
            <Controller
              name="snils"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className={s.input__item}
                  label={"СНИЛС"}
                  type="text"
                  errors={errors}
                  ref={field.ref}
                  placeholder="Введите номер СНИЛС"
                  mask="999-999-999 99"
                />
              )}
            />
            <Input
              className={s.input__item}
              label={"Фамилия"}
              type="text"
              name="lastName"
              placeholder="Введите фамилию"
              errors={errors}
              register={register}
            />
            <Input
              className={s.input__item}
              label={"Структурное подразделение"}
              type="text"
              name="organization"
              placeholder="Введите название подразделения"
              errors={errors}
              register={register}
            />
            <Controller
              control={control}
              name="education_level"
              render={({ field }) => (
                <Select
                  {...field}
                  className={s.input__item}
                  label={"Уровень образования"}
                  errors={errors}
                  type="select"
                  name="education_level"
                  placeholder="Выберите уровень образования"
                  data={educationLevel}
                  isLoading
                />
              )}
            />
            <Input
              className={s.input__item}
              label={"Отчество"}
              type="text"
              name="patronymic"
              errors={errors}
              placeholder="Введите отчество"
              register={register}
            />
            {/* <Input
          className={s.input__item}
          label={"Структурное подразделение"}
          type="text"
          name="department"
          errors={errors}
          placeholder="Введите название подразделения"
          register={register}
        /> */}

            <Controller
              control={control}
              name="department"
              render={({ field }) => (
                <Select
                  {...field}
                  className={s.input__item}
                  label={"Организация"}
                  type="select"
                  errors={errors}
                  name="department"
                  placeholder="Введите название организации"
                  // value={
                  //   departments?.find(
                  //     (department) => department[0] === profileFormData?.department
                  //   )?.[1] // ВЫНЕСТИ ЛОГИКУ, ЭТО ДЛЯ ДЕФОЛТНОГО ЗНАЧЕНИЯ В ДРОПДАУНЕ
                  // }
                  value={currentDepartment}
                  data={departments?.map((region) => region[1]).sort()}
                  isLoading
                />
              )}
            />

            <Controller
              control={control}
              name="citizenship"
              render={({ field }) => (
                <Select
                  {...field}
                  className={s.input__item}
                  label={"Гражданство"}
                  type="select"
                  value={currentCountry}
                  name="citizenship"
                  errors={errors}
                  placeholder="Выберите страну"
                  data={countries.map((country) => country[1]).sort()}
                  isLoading
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className={s.input__item}
                  label={"Телефон"}
                  type="tel"
                  errors={errors}
                  ref={field.ref}
                  placeholder="+7 (916) 645-99-45"
                  mask="+7 (999) 999-99-99"
                />
              )}
            />
            <Controller
              control={control}
              name="federal_subject"
              render={({ field }) => (
                <Select
                  {...field}
                  className={s.input__item}
                  label={"Федеральный субъект"}
                  type="select"
                  errors={errors}
                  name="federal_subject"
                  value={currentRegion}
                  placeholder="Выберите регион"
                  data={regions.map((region) => region[1]).sort()}
                  isLoading
                />
              )}
            />
            <Input
              className={s.input__item}
              label={"Почта"}
              type="email"
              name="email"
              placeholder="Введите вашу почту"
              errors={errors}
              register={register}
            />
            <Input
              className={s.input__item}
              label={"Дата рождения"}
              type="date"
              errors={errors}
              name="birth_date"
              placeholder="Выберите дату рождения"
              register={register}
            />
            {/* <Controller
          control={control}
          name="birth_date"
          render={({ field }) => (
            <div className={s.input__item}>
              <label>Дата рождения</label>
              <DatePicker
                placeholderText="Выберите дату рождения"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd.MM.yyyy"
                className={`${s.input} ${errors.birth_date ? s.error : ""}`}
              />
              {errors.birth_date && (
                <p className={s.errorText}>{errors.birth_date.message}</p>
              )}
            </div>
          )}
        /> */}

            <Controller
              control={control}
              name="gender"
              className={s.input__item}
              render={({ field }) => (
                <Select
                  {...field}
                  label={"Пол"}
                  type="select"
                  data={gender}
                  name="gender"
                  errors={errors}
                  className={s.input__item}
                  placeholder={"Пол"}
                  isLoading
                />
              )}
            />
          </div>
          <Button
            className={s.form__button}
            submit
            type="blue"
            text="Сохранить"
          />
        </>
      )}
    </form>
  );
};

const PasswordForm = () => {
  const navigate = useNavigate();
  const { setPasswordFormData } = usePasswordForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordValidationSchema),
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },
  });

  const onChangePassword = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            old_password: data.old_password,
            new_password: data.new_password,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Произошла ошибка ${response.status} ${response.statusText}`
        );
      }

      alert("Пароль успешно изменен!");
      navigate("/");
    } catch (error) {
      console.error("Произошла ошибка при смене пароля", error);
      alert("Старый пароль неверен!");
    }
  };

  return (
    <form
      className={s.form__password}
      onSubmit={handleSubmit(onChangePassword)}
    >
      <div className={s.form__password_inputs}>
        <Input
          className={clsx(s.input__item, s.input__password)}
          label={"Старый пароль"}
          type="password"
          name="old_password"
          showToggle
          placeholder="Введите старый пароль"
          errors={errors}
          register={register}
          page="profile"
          isLoading
        />
        <Input
          className={clsx(s.input__item, s.input__password)}
          label={"Новый пароль"}
          type="password"
          name="new_password"
          showToggle
          placeholder="Введите новый пароль"
          errors={errors}
          register={register}
          page="profile"
          isLoading
        />
        <Input
          className={clsx(s.input__item, s.input__password)}
          label={"Повторите новый пароль"}
          type="password"
          showToggle
          name="confirm_new_password"
          placeholder="Повторите новый пароль"
          errors={errors}
          register={register}
          page="profile"
          isLoading
        />
      </div>
      <Button
        className={s.form__button}
        submit
        type="blue"
        text="Сохранить данные"
      />
    </form>
  );
};

const ProfileContent = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { profileFormData } = useProfileForm();

  return (
    <Layout>
      <Container className={s.wrapper}>
        <Title>Мой профиль</Title>
        <div className={s.card__info}>
          {profileFormData?.firstName || profileFormData?.lastName ? (
            <>
              <p>{profileFormData?.firstName}</p>
              <p>{profileFormData?.lastName}</p>
            </>
          ) : (
            <Spinner className={s.spinner} />
          )}
        </div>
        <div className={s.tabs}>
          <Button
            type={activeTab === "profile" ? "blue" : "lightblue"}
            className={`${s.tabs__button} ${
              activeTab === "profile" ? s.active : ""
            }`}
            text="О себе"
            onClick={() => setActiveTab("profile")}
          />
          <Button
            type={activeTab === "password" ? "blue" : "lightblue"}
            className={`${s.tabs__button} ${
              activeTab === "password" ? s.active : ""
            }`}
            text="Пароль"
            onClick={() => setActiveTab("password")}
          />
        </div>
        {activeTab === "profile" ? <ProfileForm /> : <PasswordForm />}
      </Container>
    </Layout>
  );
};

export const Profile = () => (
  <ProfileFormProvider>
    <PasswordFormProvider>
      <ProfileContent />
    </PasswordFormProvider>
  </ProfileFormProvider>
);
