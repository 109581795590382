import { ActivateForm } from "../ActivateForm/ActivateForm";
import s from "./ActivateBlock.module.css";
import clsx from "clsx";

export const ActivateBlock = ({ className, email, code }) => {
  return (
    <div className={clsx(s.authorize__block, [className])}>
      <h1 className={s.title}>Придумайте пароль</h1>
      <div className={s.text__block}>
        {/* Почта приходит Query параметром из адресной строки */}
        <p>
          Ваша почта <span className={s.mail}>{email}</span>
        </p>
        <p>Чтобы попасть в личный кабинет, необходимо придумать пароль</p>
      </div>
      <ActivateForm email={email} code={code} />
    </div>
  );
};
