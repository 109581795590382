import s from "./Chip.module.css";
import clsx from "clsx";
import { useState } from "react";

//виды чипов(green, disabled, check)
export const Chip = ({
  text,
  type,
  onClick = () => {},
  isActive,
  q,
  unactive,
}) => {
  const handleClick = () => {
    onClick(text); // Теперь просто вызываем onClick с текстом
  };

  return (
    <button
      onClick={() => handleClick(text)}
      className={clsx(
        s.Chip,
        s[type],
        isActive && s.check,
        unactive && s.unactive
      )}
    >
      {text}
      <sup className={s.quantity}>{q}</sup>
    </button>
  );
};
