import s from "./CompetenceCard.module.css";
import clsx from "clsx";
import book from "../../assets/icons/book.svg";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../ui/Spinner/Spinner";
import { getLevelCategory } from "../../helpers/getLevelCategory";
import { formatToRussianDate } from "../../helpers/formatToRussianDate";
import { getBarStyle } from "../../helpers/getBarStyle";

export const CompetenceCard = ({ sphereId, sphereName }) => {
  // РЕЗУЛЬТАТЫ ВСЕХ ОЦЕНОЧНЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ КОМПЕТЕНЦИЙ
  const [sphereAllResults, setSphereAllResults] = useState(null);
  // РЕЗУЛЬТАТЫ ПОСЛЕДНЕЙ ОЦЕНОЧНОЙ СЕССИИ ПО ДАННОЙ СФЕРЕ КОМПЕНЦИЙ
  const [sphereLastResult, setSphereLastResult] = useState(null);
  // ДАТА ПОСЛЕДНЕЙ ОЦЕНОЧНОЙ СЕССИИ
  const [date, setDate] = useState("");
  // Состояние загрузки
  const [loading, setLoading] = useState(true);

  // ПОЛУЧАЕМ С СЕРВЕРА РЕЗУЛЬТАТЫ ЗАВЕРШЕННЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ КОМПЕТЕНЦИЙ
  useEffect(() => {
    const fetchSphereData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/sphere/${sphereId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await response.json();

        if (data && data.length > 0) {
          const latestData = data[data.length - 1];
          setDate(formatToRussianDate(latestData.updated_at));
          setSphereAllResults(data);
          setSphereLastResult(latestData);
        }
      } catch (error) {
        console.error("Ошибка при получении данных сферы:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSphereData();
  }, [sphereId]);

  const isLevelDefined = sphereAllResults?.find((result) => {
    return result.level !== null && result.level !== undefined;
  });

  return (
    <Link to={isLevelDefined ? `/result/${sphereId}` : "#"}>
      <div className={s.card}>
        {loading ? (
          <Spinner className={s.spinner} />
        ) : (
          <>
            <div className={s.wrapper}>
              <img className={s.sphere__icon} src={book} alt="book" />
              <p className={s.sphere__name}>{sphereName}</p>
              <p className={s.sphere__date}>{isLevelDefined && date}</p>
            </div>

            <div className={s.wrapper}>
              <div className={s.level__rang}>
                {isLevelDefined ? (
                  <div className={s.level__wrapper}>
                    <img
                      className={s.level__icon}
                      src={getLevelCategory(sphereLastResult.level).icon}
                      alt="level"
                    />
                    <p
                      className={clsx(
                        s.level__text,
                        getLevelCategory(sphereLastResult.level).className
                      )}
                    >
                      {getLevelCategory(sphereLastResult.level).text}
                    </p>
                  </div>
                ) : (
                  <p className={clsx(s.level__text, s.level__text_disabled)}>
                    Не установлено
                  </p>
                )}
              </div>

              <div className={s.level__wrapper}>
                {isLevelDefined &&
                  (() => {
                    const lastValidIndex = sphereAllResults.reduce(
                      (lastIndex, el, i) =>
                        el.level !== null && el.level !== undefined
                          ? i
                          : lastIndex,
                      -1
                    );

                    return sphereAllResults.map((el, i) => {
                      if (el.level !== null && el.level !== undefined) {
                        return (
                          <div
                            className={s.level__rating}
                            key={i}
                            style={getBarStyle(
                              getLevelCategory(el.level).text,
                              i === lastValidIndex
                            )}
                          />
                        );
                      }
                      return null;
                    });
                  })()}
              </div>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};
