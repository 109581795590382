import { Button } from "../Button/Button";
import { Chip } from "../Chip/Chip";
import s from "./CardTask.module.css";
import lock from "./img/lock.svg";
import clsx from "clsx";
import { Spinner } from "../Spinner/Spinner";

//виды карточек type:(active, disabled)
export const CardTask = ({
  task,
  text,
  complete,
  type,
  date,
  endDate,
  level,
  isLoading,
  test, // Кнопка начать тест
}) => {
  return (
    <div className={clsx(s.CardTask, s[type], complete && s.active)}>
      {isLoading ? (
        <Spinner className={s.spinner} />
      ) : (
        <>
          <p className={s.title}>{task}</p>
          {/* ПОДПРАВИТЬ для сессий  */}
          {date && <p className={s.date}>{date}</p>}
          {complete && (
            <Chip
              text="Пройдено"
              type="green"
              unactive={complete} /* Кнопка неактивна, если сессия выполнена */
            />
          )}
          {level && (
            <Chip
              text={level.text}
              type={level.type}
              unactive={complete} /* Кнопка неактивна, если сессия выполнена */
            />
          )}
          <p className={s.descr} title={text}>
            {text}
          </p>

          {test && !complete && (
            <Button
              type="green"
              text="Начать тестирование"
              className={s.button}
            />
          )}

          {endDate && (
            <p className={s.endDate}>
              Завершить до <span>{endDate}</span>
            </p>
          )}
          {type === "disabled" && (
            <img className={s.lockImg} src={lock} alt="Disabled" />
          )}
        </>
      )}
    </div>
  );
};
