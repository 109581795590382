import { useLocation, useParams } from "react-router-dom";
import s from "./RecoveryPasswordPage.module.css";
import { Layout } from "../../layouts/Layout";
import { Container } from "../../layouts/Container";
import { RecoveryPasswordForm } from "../../components/RecoveryPasswordForm/RecoveryPasswordForm";

export const RecoveryPasswordPage = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const token = params.get("token");

  return (
    <Layout className={s.authorize}>
      <Container className={s.container}>
        <div className={s.authorize__block}>
          <h1 className={s.title}>Придумайте новый пароль</h1>
          <p className={s.text}>
            Чтобы попасть в личный кабинет, необходимо придумать пароль
          </p>
          <RecoveryPasswordForm token={token} email={email} />
        </div>
      </Container>
    </Layout>
  );
};
