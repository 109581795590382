import s from "./RecoveryPasswordForm.module.css";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Button } from "../../ui/Button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../ui/Input/Input";

const schema = Yup.object().shape({
  password: Yup.string()
    .min(
      8,
      "Ненадежный пароль. Пароль должен содержать не менее 8 символов, буквы верхнего и нижнего регистра, цифры и символы: @$!%*?&#"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/,
      "Пароль должен содержать буквы верхнего и нижнего регистра, цифры и символы: @$!%*?&#"
    ),
  confirmPassword: Yup.string()
    .required("Подтверждение пароля обязательно")
    .oneOf([Yup.ref("password")], "Пароли не совпадают"),
});

export const RecoveryPasswordForm = ({ email, token }) => {
  const navigate = useNavigate();
  const [recoveryPasswordError, setRecoveryPasswordError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onSubmit" });

  const onSubmit = async (data) => {
    // Отправляемые данные
    const payload = {
      token: token,
      email: email,
      new_password: data.password,
    };

    /*     console.log(payload); */

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      /*       console.log(response);
       */
      const jsonData = await response.json();

      if (response.ok) {
        const loginPayload = {
          email: email,
          password: data.password,
        };

        /*         console.log(loginPayload); */

        const loginResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loginPayload),
          }
        );

        /*         console.log(loginResponse); */
        const loginData = await loginResponse.json();

        if (loginResponse.ok) {
          localStorage.setItem("token", loginData.token);
          navigate("/profile", { replace: true });
        } else {
          setRecoveryPasswordError(loginData.error);
          console.error(
            "Ошибка входа:",
            loginData.error || `Status: ${loginResponse.status}`
          );
          throw new Error(
            loginData.error || `HTTP error! status: ${loginResponse.status}`
          );
        }
      } else {
        setRecoveryPasswordError(jsonData.error);
        console.error(
          "Ошибка:" + jsonData.error || `Status: ${response.status}`
        );
        throw new Error(
          jsonData.error || `HTTP error! status: ${response.status}`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form} noValidate>
      <div className={s.wrapper}>
        <Input
          type="password"
          placeholder="Введите пароль"
          name="password"
          register={register}
          errors={errors}
          errorMessage={recoveryPasswordError}
          showToggle
          backgroundColor="white"
        />

        <Input
          type="password"
          placeholder="Повторите ваш пароль"
          name="confirmPassword"
          register={register}
          errors={errors}
          errorMessage={recoveryPasswordError}
          showToggle
          backgroundColor="white"
        />
      </div>

      <Button
        className={s.button}
        submit
        text="Сохранить и войти"
        type={"blue"}
      />

      {recoveryPasswordError && (
        <p className={s.error}>{recoveryPasswordError}</p>
      )}
    </form>
  );
};
