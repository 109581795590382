import s from "./Header.module.css";
import {Logo} from '../../ui/Logo/Logo'
import {Container} from '../Container/Container'


export function Header(){
  return <header className={s.Header}>
    <Container>
        <Logo/>
    </Container>
  </header>;
};
