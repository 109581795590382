import { useNavigate } from "react-router-dom";
import s from "./ResetPasswordPage.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Layout } from "../../layouts/Layout";
import { Container } from "../../layouts/Container";
import { ResetPasswordForm } from "../../components/ResetPasswordForm/ResetPasswordForm";
import { useState } from "react";

export const ResetPasswordPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [email, setEmail] = useState("");

  const modalHandler = (emailValue) => {
    if (!isOpenModal) {
      setEmail(emailValue);
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  };

  return (
    <Layout className={s.reset}>
      <Container className={s.container}>
        {/*         {isOpenModal && (
          <div className={s.modal}>
            <div className={s.modal__content}>Modsdasdasal</div>
          </div>
        )} */}

        <div className={clsx(s.reset__block)}>
          <h1 className={s.title}>Восстановление пароля</h1>
          <ResetPasswordForm modalHandler={modalHandler} />
          <Link className={s.link} to="/login">
            <button className={s.back}>Назад</button>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};
